<template>
  <div class="person-ranking">
    <div class="top-title">
<!--      <s-section-title-bar>活跃党员名单</s-section-title-bar>-->
      <s-section-title-bar>活跃党支部名单</s-section-title-bar>
      <div class="ranking-period">
        <span style="margin-right: 10px">排名周期:</span>
        <el-date-picker
          v-model="month"
          :editable="false"
          value-format="yyyy-MM"
          type="month"
          @change="monthChange"
          placeholder="选择月份">
      </el-date-picker></div>
    </div>


    <div class="person-table"
         v-loading="loading"
         element-loading-text="数据加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)">

<!--      <div class="table-head">
        <div v-if="Object.keys(personRankingList).length > 0">党员姓名</div>
        <div v-if="Object.keys(personRankingList).length > 0">所属支部</div>

        <div>账号</div>
        <div>综合得分</div>
      </div>-->

      <div class="table-item" v-for="(item,index) of personRankingList.slice(0,5)" :key="index">
        <div class="ranking-num" :class="[`ranking-num-${index}`]">{{ Number(index)+1 }}</div>
        <div class="person-info">
          <div class="unit_name">{{ item.name }}</div>
<!--          <div class="examine-person">{{ item.name }}</div>-->
<!--          <div class="subjection-unit">{{ item.unit }}</div>-->

<!--          <div class="person-phone">{{ item.phone }}</div>-->
<!--          <div class="synthesis-score">{{ item.score }}</div>-->
        </div>
      </div>

      <div class="hint-text" v-if="!this.loading&&Object.keys(personRankingList).length === 0">
        未更新排名数据
      </div>

    </div>

  </div>
</template>

<script>
  import {mapState} from "vuex"
  export default {
    name: "person-ranking",
    data() {
      return {
        month:'',
        list: [
          { id: 1, rank: 1, unit: '中共河南省荥阳市新华书店有限公司支部委员会',    name: '乔夕文',   phone: '181****1880' },
          { id: 2, rank: 2, unit: '中共河南省新密市新华书店有限公司支部委员会', name: '蔡冬云', phone: '157****9966' },
          { id: 3, rank: 3, unit: '中共河南省巩义市新华书店有限公司支部委员会',   name: '夏陶宁', phone: '186****1111' },
          { id: 4, rank: 4, unit: '中共河南省登封市新华书店有限公司总支部委员会',   name: '邹文石',  phone: '139****5536' },
          { id: 5, rank: 5, unit: '中共河南省新郑市新华书店有限公司支部委员会',   name: '周子平',  phone: '130****9050' },
          { id: 6, rank: 6, unit: '中共河南省中牟县新华书店有限公司支部委员会',   name: '张睿广',  phone: '130****9050' },
          { id: 7, rank: 7, unit: '中共河南省固始县新华书店有限公司总支部委员会',   name: '叶英博',  phone: '130****9050' },
          { id: 8, rank: 8, unit: '中共河南省光山县新华书店有限公司支部委员会',   name: '周子明',  phone: '130****9050' },
        ],
      }
    },

    computed: {
      ...mapState({
        personRankingList: state => state.routine.personRankingList,
        rankingCycleTime: state => state.routine.rankingCycleTime,
        loading: state => state.routine.GoodLoading,
      })
    },
    watch:{
      personRankingList(newval){
        // console.log(newval,'优秀党员')
      },
    },
    mounted() {
      let th = new Date();
      let year = th.getFullYear()
      let month = th.getMonth() + 1
      let monthsData = year+"-"+month
      this.month = monthsData
    },
    methods:{
      monthChange(){
        this.$store.dispatch('getExaminePersonRankingData', {page:1,limit:10,date:this.month});
        // console.log(this.month,'月份')
      },
    }

  }
</script>

<style lang="scss" scoped>

  @import "../../styles/mixin";

  .person-ranking {
    width: 100%;
    padding: 0 19px;
    margin-top: 10px;

    .top-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      //margin-bottom: 10px;

      .ranking-period {
        white-space: nowrap;
        font-size: 14px;
        font-weight: 400;
        color: #C0CCE4;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width:50%;

        ::v-deep.el-input__inner{
          background-color: #203b87;
          border: 1px solid #203b87;
          color: #fcfcfd;
        }
      }

    }

    .person-table {
      //margin-top: 10px;

      .table-head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding-left: 26px;
        font-size: 14px;
        font-weight: 400;
        color: #8799C0;
        //text-align: center;
        div:nth-child(1) {
          width: 19%;
          text-align: right;
          //padding-right: 15%;
        }
        div:nth-child(2) {
          padding-left: 5%;
          width: 50%;
        }
        div:nth-child(3) {
          width: 20%;
        }
        div:nth-child(4) {
          width: 20%;
          color: #E8EBF0;
        }
      }

      .table-item {
        $font-color: #C0CCE4;
        display: flex;
        align-items: center;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: $font-color;

        & + .table-item {
          margin-top: 20px;
        }

        .ranking-num {
          width: 16px;
          height: 16px;
          color: #EEF4F6;
          margin-right: 10px;
          text-align: center;
          line-height: 17px;
          border-radius: 50%;

          &.ranking-num-0 {
            color: #0C1130;
            background-color: #eeb13d;
          }
          &.ranking-num-1 {
            color: #0C1130;
            background-color: #4390d6;
          }
          &.ranking-num-2 {
            color: #0C1130;
            background-color: #7f4c18;
          }
        }

        .person-info {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .unit_name{
            @include ellipsis(1);
            width: 100%;
          }
          .examine-person {
            @include ellipsis(1);
            width: 28%;
            text-align: center;
          }
          .subjection-unit {
            @include ellipsis(1);
            width: 90%;
          }
          .person-phone {
            @include ellipsis(1);
            width: 20%;
            text-align: center;
          }
          .synthesis-score {
            @include ellipsis(1);
            width: 20%;
            color: #EEF4F6;
            text-align: center;
          }
        }

      }

      .hint-text {
        width: 100%;
        //height: 250px;
        font-weight: 400;
        font-size: 14px;
        //line-height: 250px;
        color: $medium-font-color;
        text-align: center;
        height: 180px;
        line-height: 180px;
      }

    }

  }
</style>

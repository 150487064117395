<template>
  <div class="btn-group-content" :style="[btnGroupContentStyle]">

    <div class="btn-group" :style="[btnGroupStyle]" :class="[`btn-group-${size}`]">
      <div
        class="btn-active"
        :style="[activeBtnStyle]"
        @click="handleActive(current)"
      ></div>

      <div
        :ref="`btnItem${index}`"
        class="btn-item"
        v-for="(item, index) of list"
        :key="index"
        :class="{'is-active': current === index}"
        @click="handleActive(index)"
      >{{ item.name }}</div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {Loading} from "element-ui";

export default {
  name: "btn-group",
  props: {
    list: {
      type: Array,
      default: () => [],
    },

    display: {
      type: String,
      default: 'block'
    },

    justify: {
      type: String,
      default: 'start',
    },

    margin: {
      type: String,
      default: '0 0 0 0',
    },

    padding: {
      type: String,
      default: '0 0 0 0'
    },


    fontSize: {
      type: String,
      default: '14px',
    },

    backgroundColor: {
      type: String,
      default: '#284085'
    },

    color: {
      type: String,
      default: '#9DB9EB'
    },


    size: {
      type: String,
      default: "mini",
    },

  },

  data() {
    return {
      current: 0,
      btnItemLeftList: [],
      btnItemWidthList: [],
    }
  },

  computed: {

    btnGroupContentStyle() {
      return {
        display: this.display,
        justifyContent: this.justify,
        width: this.display !== 'flex' ? 'fit-content' : '',
        margin: this.margin,
        padding: this.padding,
      }
    },
    ...mapState({
      type: state => state.electronic.type,
      org_area_id: state => state.user.org_area_id,
    }),

    activeBtnStyle() {
      return {
        left: this.btnItemLeftList[this.current] + "px",
        width: this.btnItemWidthList.length ? this.btnItemWidthList[this.current] + 'px' : '50%',
      }
    },

    btnGroupStyle() {
      return {
        borderRadius: '30px',
        fontSize: this.fontSize,
        color: this.color,
        backgroundColor: this.backgroundColor,
      }
    },

  },

  mounted() {
    this.getItemWidth();
  },

  methods: {

    getItemWidth() {
      Object.values(this.$refs).forEach(([item]) => {
        this.btnItemLeftList.push(item.offsetLeft);
        this.btnItemWidthList.push(item.clientWidth);
      })
    },

    handleActive(index) {
      this.current = index;
      let activeName = this.$store.state.electronic.activeName
      let active_name = this.$store.state.electronic.active_name

      if (this.current == 1) {

        this.$store.dispatch('getECShopCheckSituation', {org_area_id: this.org_area_id});
        this.$store.dispatch('getECShopCheckAvgValue')
        this.$store.dispatch('getSubordinateUnitCheckSituation', {org_area_id: this.org_area_id})
        this.$store.dispatch('getNearFutureCheckShopNum', {org_area_id: this.org_area_id})
        this.$store.dispatch('getSubordinateUnitECShopNum', {org_area_id: this.org_area_id})
        this.$store.dispatch('getECShopTypeRatio')
        this.$store.dispatch('eCShopCheckCycle')
        let params = {'limit': 5, 'page': 1, org_area_id: this.org_area_id}
        this.$store.dispatch('getTodayECShopCheckRecordList', params)

      } else {

        const org_area_id = this.$store.getters.org_area_id;
        let that = this

        function runAsync1() {
          var state = new Promise(function (resolve, reject) {
            //异步获取节点
            var timeOut = setInterval(function () {
              let chartDOM = document.getElementById('examine-columnar-chart');
              if (chartDOM != null) {
                resolve(chartDOM, timeOut);
                clearInterval(timeOut)
              }
            }, 100);

          });
          return state;
        }

        runAsync1()
          .then(function (data) {
            if (data != null) {

              that.$store.dispatch('getCheckRealTimeData', {org_area_id});
              that.$store.dispatch('getCheckAreaInsideData', {org_area_id});
              that.$store.dispatch('getNewExamineRecordData', {org_area_id});

              that.$store.dispatch('cycleCheckRealTime');
              that.$store.dispatch('getDailyComparisonData', {org_area_id});
              that.$store.dispatch('getCheckStatisticsData', {org_area_id, time_type: activeName});
              that.$store.dispatch('getExaminePersonRankingData', {org_area_id});
              that.$store.dispatch('getCheckSubordinateUnitData', {org_area_id: that.$store.getters.parent_id || org_area_id});
              that.$store.dispatch('getCheckContentStatisticsData', {time_type: active_name, org_area_id});

            }
          })
      }
      // this.$store.dispatch('getECShopCheckSituation');
      this.$store.commit('SwitchType', this.current);
    },

  },
}
</script>

<style lang="scss" scoped>
.btn-group-content {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .btn-group {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 30px;

    &.btn-group-mini .btn-item {
      padding: 4px 10px;
    }

    &.btn-group-medium .btn-item {
      padding: 14px 18px;
    }

    &.btn-group-small .btn-item {
      padding: 4px 10px;
    }

    .btn-item {
      position: relative;
      z-index: 5;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;
      transition: background-color .1s, color .2s;

      &.is-active {
        color: #fff;
      }

      &:active {
        background-color: #243976;
      }
    }

    .btn-active {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      background-image: linear-gradient(to right, #203b87, #213e97);
      line-height: 28px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      color: $medium-font-color;
      background-color: #203b87;
      border-radius: 30px;
      border: 1px solid #2254fb;
      cursor: pointer;
      transition: left .05s;
      box-shadow: rgba(0, 0, 0, 0.1) 10px 0px 40px 0px
    }

  }

}
</style>

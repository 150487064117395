<template>
  <bm-overlay
    :style="dotColor"
    :ref="`customOverlay${index}`"
    class="dot-marker"
    pane="labelPane" @draw="draw">
    <div class="dot-item" :style="{opacity}"></div>

    <div class="info-frame">
      <p class="lice_no">组织名称：{{ info.name || '' }}</p>
      <p class="check-shop-name">组织类型：{{ info.organization_type_name || '' }}</p>
      <p class="check-shop-name">地址：{{ info.address || '' }}</p>
    </div>
  </bm-overlay>
</template>

<script>
  import BmOverlay from "../vue-baidu-map/components/overlays/Overlay"
  export default {
    name: "dot-overlay",
    components: { BmOverlay },

    props: {
      index: {
        type: Number,
        default: ""
      },

      position: {
        type: Object,
        default: { lng: "116.397497", lat: "39.906888" }
      },

      color: {
        type: String,
        default: 'red'
      },

      info: {
        type: Object,
        default: () => ({})
      },

      billows: {
        type: String,
        default: "rgba(255, 0, 0, .25)",
      },
    },

    data() {
      return {
        opacity: 0,
      }
    },

    computed: {
      dotColor() {
        return {
          "--dot-color": this.color,
          "--stroke-color": this.billows,
        }
      },
    },

    watch: {
      position: {
        deep: true,
        handler () {
          this.$refs[`customOverlay${this.index}`].reload()
        }
      }
    },

    mounted() {
      this.opacity = 0.6;
    },

    methods: {
      draw ({el, BMap, map}) {
        const {lng, lat} = this.position
        const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat))
        el.style.left = pixel.x + 'px'
        el.style.top = pixel.y + 'px'
      },
    }

  }
</script>

<style lang="scss" scoped>
  .dot-marker {
    position: absolute;
    width: 30px;
    height: 30px;
    //background-color: rgba(0,0,0,.4);
    transform: translate(-50%, -50%);
    cursor: pointer;

    @keyframes animate {
      0% {
        width: 0;
        height: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        width: 30px;
        height: 30px;
        opacity: 0;
      }
    }

    &:hover > .info-frame {
      opacity: 1;
      visibility: inherit;
    }

    .dot-item {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 9px;
      height: 9px;
      background-color: var(--dot-color);
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 1s;
    }

    .info-frame {
      position: absolute;
      top: 24px;
      left: 24px;
      padding: 10px 12px 8px;
      background-color: rgba(255,255,255,.15);
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      opacity: 0;
      transition: opacity .15s;
      visibility: hidden;

      .lice_no {
        white-space: nowrap;
        width: 100%;
        font-size: 12px;
        padding-bottom: 4px;
        border-bottom: 1px solid #fff;
      }

      .check-shop-name {
        font-size: 14px;
        margin-top: 4px;
        white-space: nowrap;
      }
      .check-shop-address {
        font-size: 12px;
        margin-top: 2px;
        white-space: nowrap;
      }
    }

    .info-check-frame {
      @extend .info-frame;
      background-color: rgba(255,255,255,.25);

      .check-shop-name {
        margin-bottom: 10px;
      }

      .lice_no,
      .check-shop-address {
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 0;

        .title {
          width: 30%;
          white-space: nowrap;
        }
        .value {
          white-space: nowrap;
        }

      }

      .check-info-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, .5);

        .main-info {

          .examine-date,
          .examine-person,
          .examine-count {
            display: flex;
            align-items: center;
            font-size: 12px;

            & + div {
              margin-top: 8px;
            }
            .title {
              width: 70px;
              white-space: nowrap;
            }
            .value {
              white-space: nowrap;
            }
          }
        }

        .shop-photo {
          display: block;
          width: 30px;
          height: 30px;
          margin-left: 20px;
          object-fit: cover;
        }
      }

    }



  }
</style>

<template>
  <div class="center-container">

    <header class="bi-header">
<!--      <h1 class="bi-title" v-if="type==0">中原出版传媒集团智慧党建数据大屏</h1>-->
      <h1 class="bi-title flex-h1">
<!--        <img class="img" :src="$currency.ossFileURL('zy_logo')" alt="" />-->
        智慧党建数据管理中心
      </h1>

      <main class="main-data">
        <div class="data-item">
          <countTo
            class="item-count"
            :startVal="startNum1"
            :endVal="areaInsideList.all_unit_total"
            :duration="1500" />
          <span class="item-name">支部数量</span>
        </div>
        <div class="data-item">
          <countTo class="item-count" :startVal="startNum2" :endVal="areaInsideList.all_unit_user_total" />
          <span class="item-name">党员总数</span>
        </div>
        <div class="data-item">
          <countTo class="item-count" :startVal="startNum3" :endVal=areaInsideList.visit_total />
          <span class="item-name">今日党员活跃数</span>
        </div>
      </main>
    </header>

    <section class="map-content">
      <map-container />
    </section>

  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import mapContainer from "./map-container"
  export default {
    name: "center-container",

    components: {
      mapContainer,
    },

    data() {
      return {
        startNum1: 0,
        startNum2: 0,
        startNum3: 0,
      }
    },

    computed: {
      ...mapState({
        areaInsideList: state => state.routine.Profile_data,
        address: state => state.address.address,
        all_check_num: state => state.routine.centerTopData.all_check_num,
        total_check_num: state => state.routine.centerTopData.total_check_num,
        hold_cert_shop_num: state => state.routine.centerTopData.hold_cert_shop_num,
        type: state => state.electronic.type,
        // electronicchart: state => state.electronic.electronicchart,
      })
    },

    watch: {
      areaInsideList(newVal){},

      all_check_num(newVal, oldVal) {
        this.startNum1 = oldVal;
      },

      hold_cert_shop_num(newVal, oldVal) {
        this.startNum2 = oldVal;
      },

      total_check_num(newVal, oldVal) {
        this.startNum3 = oldVal;
      },



    },

  }
</script>

<style lang="scss" scoped>

  @import "../../styles/mixin";
  $header-title-bg: url('http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/header-title-bg.png');
  $bg-img1: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg2.png");
  $bg-img2: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg.png");

  .center-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    //height: 100%;
    //background-color: #09f;

    .bi-header {
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
      width: 100%;
      background-image:  $bg-img1;
      background-size: 1920px;
      background-position: center top;


      .bi-title {
        width: 636px;
        height: 81px;
        margin: 0 auto 27px;
        background-image: $header-title-bg;
        background-repeat: no-repeat;
        background-size: 100% auto;
        font-size: 34px;
        text-align: center;
        line-height: 81px;
        letter-spacing: 2px;
        @include ellipsis(1);
      }
      .flex-h1{
        display: flex;
        align-items: center;
        justify-content: center;
        .img{
          width: 50px;
          height: 50px;
          object-fit: contain;
          margin-right: 10px;
        }
      }

      .main-data {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        //height: 132px;
        //margin-top: 5px;

        .data-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 3px;
          width: 218px;
          height: 90px;

          &:nth-child(1n + 4) {
            margin-top: 10px;
          }

          & + .data-item::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: 30px;
            background-color: #4C6D98;
            transform: translateY(-50%);
          }

          .item-count {
            font-size: 24px;
            color: #fff;
            font-weight: 500;
          }

          .item-name {
            @include ellipsis(1);
            margin-top: 4px;
            font-size: 14px;
            color: #8799C0;
            letter-spacing: 1px;
          }
          .item-nameType {
            @include ellipsis(1);
            font-size: 20px;
            color: #0FFFFF;
            letter-spacing: 1px;
          }

        }
      }
    }


    .map-content {
      width: 100%;
      flex: 1;
      margin-top: 90px;
    }

  }
</style>

<template>
  <div class="content-statistics">

    <s-section-title-bar>党内活动统计</s-section-title-bar>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="今天"   name="0"/>
      <el-tab-pane label="近7天"  name="1"/>
      <el-tab-pane label="近30天" name="2"/>
      <el-tab-pane label="近90天" name="3"/>
    </el-tabs>

    <div class="charts-content"
         v-loading="randomLoading"
         element-loading-text="数据加载中..."
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(15,36,87,.85)"
    >
      <canvas id="examine-feedback-chart2">您的浏览器暂不支持浏览该图表，请更换最新版本的浏览器!</canvas>
      <div id="random-chart" style="margin-top: -1%"></div>
    </div>

    <div class="unit-examine"></div>


  </div>
</template>

<script>
import {Charts} from "@/views/home/charts";
import btnGroup from "../btn-group"
import {mapState} from "vuex"
import { Loading } from 'element-ui';

export default {
  name: "content-statistics",
  components: {
    btnGroup,
  },

  data() {
    return {
      activeName: '0',
    }
  },

  computed: {
    ...mapState({
      contentStatisticsData: state => state.routine.contentStatisticsData,
      randomLoading: state => state.routine.randomLoading,
    })
  },

  watch: {
    contentStatisticsData(newVal) {
      const fakeData = newVal
      Charts.initDashBoardChart('examine-feedback-chart2','党员学习', fakeData);
      Charts.initAnnularChart('random-chart', fakeData);
    },
  },

  mounted() {
    this.activeName = '0'
  },

  methods: {

    handleClick() {
      // const params = {time_type: +this.activeName};
      this.$store.dispatch('getCheckContentStatisticsData', {time_type:this.activeName});
    },

  }
}
</script>

<style lang="scss" scoped>
  .content-statistics {
    margin-top: 35px;
    width: 100%;

    .charts-content {
      width: 100%;
      padding-right: 30px;
      display: flex;
      justify-content: space-between;
    }

    #examine-feedback-chart2 {
      padding-top: 0px;
      width: 130px;
      height: 185px;
      font-size: 14px;
      font-weight: 400;
      //margin-right: 20px;
    }
    #random-chart {
      width: calc(100% - 160px);
      height: 200px;
      font-size: 14px;
      font-weight: 400;
    }
  }
</style>

<template>
  <div class="unit-examine">
    <s-section-title-bar>党务会议完成情况</s-section-title-bar>

    <div
      id="examine-columnar-chart"
      v-loading="loading"
      element-loading-text="数据加载中..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(15,36,87,.85)"
    ></div>

  </div>
</template>

<script>
import {Charts} from "../../views/home/charts";
import {mapState} from "vuex"

export default {
  name: "unit-examine",
  data() {
    return {}

  },

  computed: {
    ...mapState({
      'subordinateUnitList': state => state.routine.subordinateUnitList,
      'loading': state => state.routine.unitExamineLoading,
    })
  },

  watch: {
    subordinateUnitList(newVal) {
      // console.log(newVal)
      const fakeDate = [
        { shop_num: newVal.first_quarter.finish_total, name: '第一季度', check_num: newVal.first_quarter.plan_total },
        { shop_num: newVal.second_quarter.finish_total, name: '第二季度', check_num: newVal.second_quarter.plan_total },
        { shop_num: newVal.third_quarter.finish_total, name: '第三季度', check_num: newVal.third_quarter.plan_total },
        { shop_num: newVal.fourth_quarter.finish_total, name: '第四季度', check_num: newVal.fourth_quarter.plan_total },
        { shop_num: newVal.other.finish_total, name: '每年至少一次', check_num: newVal.other.plan_total },
      ]
      // Charts.initColumnarChart('examine-columnar-chart', newVal);

      Charts.initColumnarChart('examine-columnar-chart', fakeDate);
    },
  },

}
</script>

<style lang="scss" scoped>
  .unit-examine {
    width: 100%;
    margin-top: 20px;
    padding: 0 19px;

    #examine-columnar-chart {
      width: 100%;
      height: 230px;
      font-size: 14px;
      font-weight: 400;
      //margin-top: 10px;
    }

  }
</style>

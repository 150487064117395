<template>
  <div class="examine-feedback">
    <s-section-title-bar>党员性别占比及年龄分布</s-section-title-bar>

    <div class="examine-feedback-info">
      <div id="examine-feedback-chart"
           v-loading="loading"
           element-loading-text="数据加载中..."
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(15,36,87,.85)"></div>
<!--      <el-progress type="dashboard" :percentage="percentage" :color="colors" :format="format"></el-progress>-->
      <div class="shop-list">
        <div id="examine-age-chart"
             v-loading="loading"
             element-loading-text="数据加载中..."
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(15,36,87,.85)"></div>
<!--        <div class="header-title">-->
<!--          <span>支部名称</span>-->
<!--        </div>-->

<!--        <div class="shop-item" v-for="(item,index) of newExamineRecordList" :key="item.id">-->
<!--        <div class="shop-item" v-for="(item,index) of list" :key="item.id">-->
<!--          <p class="shop-name" :class="[`shop-name-${['blue','orange', 'green'][index]}`]">{{ item.shop_name }}</p>-->
<!--        </div>-->
      </div>
    </div>

  </div>
</template>

<script>
  import {Charts} from "@/views/home/charts";
  import {mapState} from "vuex";

  export default {
    name: "examine-feedback",
    data() {
      return {
        list: [
          { id: 1, shop_name: "中共河南省荥阳市新华书店有限公司支部委员会", created_at: "2020-10-01 12:00:00" },
          { id: 2, shop_name: "中共河南省兰考县新华书店有限公司支部委员会", created_at: "2021-10-01 12:30:00" },
          { id: 3, shop_name: "中共河南省汝州市新华书店有限公司支部委员会", created_at: "2022-10-01 12:50:00" },
        ],

        percentage: 108,
        colors: [
          {color: '#f56c6c', percentage: 20},
          {color: '#e6a23c', percentage: 40},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 80},
          {color: '#6f7ad3', percentage: 100}
        ]

      }
    },

    computed: {
      ...mapState({
        newExamineRecordList: state => state.routine.newExamineRecordList,
        'loading': state => state.routine.unitExamineLoading,
      })
    },
    watch:{
      newExamineRecordList(newVal){
        Charts.initSexAgeTotalChart('examine-feedback-chart', newVal);
        Charts.initSexAgeAreaChart('examine-age-chart', newVal);
      }
    },

    mounted() {
      this.init()
    },

    methods: {

      format(percentage) {
        return `108`;
      },

      init() {


      }

    },
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/mixin";
  .examine-feedback {
    width: 100%;
    margin-top: 40px;

    .examine-feedback-info {
      height:115px;//固定高度，防止刷新统计图loading位移
      display: flex;
      width: 100%;
      margin-top: 20px;

      #examine-feedback-chart {
        width: 140px;
        height: 140px;
        margin-right: 20px;
        margin-top: -10px;
      }


      .shop-list {
        width: 100%;
        #examine-age-chart{
          width: 98%;
          height: 140px;
          //margin-top: -10px;
        }
        .header-title {
          display: flex;
          justify-content: center;
          padding: 0 40px 0 26px;
          margin-bottom: 15px;
          width: 100%;
          font-size: 14px;
          font-weight: normal;
          color: $mini-font-color;
          span:first-child {
            width: 43%;
            text-align: center;
          }
          span:last-child {
            width: 100%;
          }
        }

        .shop-item {
          display: flex;
          align-items: center;
          padding: 0 40px 0 10px;

          & + .shop-item {
            margin-top: 16px;
          }

          .shop-name {
            @include ellipsis(1);
            position: relative;
            width: 100%;
            padding-left: 16px;
            font-size: 14px;
            font-weight: normal;
            color: #FFF;

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              width: 6px;
              height: 6px;
              background-color: red;
              border-radius: 50%;
              transform: translateY(-50%);
            }

            &.shop-name-blue::after {
              background-color: #4A72F6;
            }
            &.shop-name-orange::after {
              background-color: #EC8644;
            }
            &.shop-name-green::after {
              background-color: #22C6ED;
            }
          }

          .shop-status {
            width: 30%;
            white-space: nowrap;
            font-size: 14px;
            font-weight: 400;
            color: $medium-font-color;
          }
        }
      }
    }

  }
</style>

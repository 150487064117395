import UCharts from "@/utils/u-charts"
const echarts = require('echarts')
require('echarts/lib/component/tooltip');

export class Charts {

    static initUChart(option, id_name, sub_title) {
        option.subtitle.name = sub_title;
        const canvas = document.getElementById(id_name);
        const ctx = canvas.getContext("2d");
        canvas.width = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        option.width = canvas.width;
        option.height = canvas.height;
        option.context = ctx;
        new UCharts(option);
    }

    static initDashBoardChart(id_name, sub_title, data={}, bool) {
        const option = {
            "type": "arcbar",
            "canvasId": "",
            "canvas2d": false,
            "background": "none",
            "animation": false,
            "timing": "easeOut",
            "duration": 1000,
            "color": [
                "#1890FF",
                "#91CB74",
                "#FAC858",
                "#EE6666",
                "#73C0DE",
                "#3CA272",
                "#FC8452",
                "#9A60B4",
                "#ea7ccc"
            ],
            "padding": [50, 0, 0, 0],
            "rotate": false,
            "errorReload": true,
            "fontSize": 13,
            "fontColor": "#666666",
            "enableScroll": false,
            "touchMoveLimit": 60,
            "enableMarkLine": false,
            "dataLabel": true,
            "dataPointShape": true,
            "dataPointShapeType": "solid",
            "tapLegend": true,
            "title": {
                "name": data.study_unit_user_total + (bool ? "%": ''),
                "fontSize": 26,
                "color": "#FFF",
                "offsetX": 0,
                "offsetY": 0
            },
            "subtitle": {
                "name": "",
                "fontSize": 12,
                "color": "#9DB9EB",
                "offsetX": 0,
                "offsetY": 45
            },
            "extra": {
                "arcbar": {
                    "type": "default",
                    "width": 12,
                    "backgroundColor": "#2C447D",
                    "startAngle": 0.75,
                    "endAngle": 0.25,
                    "gap": 2,
                    "centerX": 0,
                    "centerY": 0,
                    "linearType": "custom",
                    "customColor": ['#22C6ED'],
                }
            },
            "series": [
                {
                    "name": sub_title,
                    "data": data.study_unit_user_total,
                    "color": "#9F21F7"
                }
            ]
        };
        Charts.initUChart(option, id_name, sub_title);
    }

    static initSexAgeTotalChart(id_name='',data){
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const oneLegendHeight = Math.round((chartDOM.height / 5 / 2));
        const chart = echarts.init(chartDOM);
        let List=[
            { value: data.man_total, name:'男'  },
            { value: data.woman_total, name: '女' },
        ]
        const option = {
            tooltip: {
                trigger: 'item',
                confine:'true'
            },
            legend: {
                left: 'center',
                bottom:'0',
                icon: 'circle',
                textStyle:{
                    color: '#9DB9EB',
                }
            },
            grid: {
                left: '0',
                right: '0',
                bottom: '0',
                top:'0',
                containLabel: true
            },
            color: ['#22C6ED','#EC8644'],
            series: [
                {
                    name: '党员性别占比',
                    type: 'pie',
                    radius: '80%',
                    data: List,
                    bottom:'10%',
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        },

                    },
                    label:{//指示线样式设置
                        normal: {
                            show: true,
                            fontSize:10,
                            position: 'inner', // 数值显示在内部
                            formatter: '{c}\n{d}%', // 格式化数值百分比输出
                        },


                    },
                    // labelLine:{
                    //     normal: {
                    //         show:false,
                    //     }
                    // }
                }
            ]
        };
        chart.setOption(option);
    }

    static initSexAgeAreaChart(id_name,data){
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const oneLegendHeight = Math.round((chartDOM.height / 5 / 2));
        const chart = echarts.init(chartDOM);
        let nameData=['30岁以下', '30-40岁', '40-50岁', '50-60岁', '60岁以上']
        let NumData=Object.values(data.age_total)
        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                left: '3%',
                right: '10%',
                bottom: '3%',
                top:'1%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    textStyle: {
                        color: '#9DB9EB'
                    },
                },
            },
            yAxis: {
                type: 'category',
                data: nameData,
                axisLabel: {
                    textStyle: {
                        color: '#9DB9EB'
                    },
                },
            },
            series: [
                {
                    name:'数量',
                    type: 'bar',
                    barWidth: '60%',
                    data:  NumData,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            2, 0, 0, 1,
                            [
                                {offset: 0, color: '#22C6ED'},
                                {offset: 1, color: '#213FF7'}
                            ]
                        )
                    },
                }
            ]
        };
        chart.setOption(option);
    }

    static  initAnnularChart(id_name='', data) {
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const oneLegendHeight = Math.round((chartDOM.height / 5 / 2));
        const chart = echarts.init(chartDOM);
        let option = {
            tooltip: {
                trigger: 'item'
            },
            grid: {
                left: '3%',
                right: '2%',
                bottom: '0%',
                top:'-30%',
            },
            legend: {
                orient: 'vertical',
                left: 'right',
                y:'center',
                itemHeight:'12',
                itemWidth:'12',
                icon: 'circle',
                textStyle:{
                    color: '#fff',
                }
            },
            color: ['#972CF6', '#6D62F3', '#EC8644', '#419DEF','#22C6ED', '#749f83', '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
            series: [
                {
                    title:{
                        text:'%' +"\n"+"成功占比率" ,   //图中间的值
                    },
                    type: 'pie',
                    radius: ['30%', '40%'],
                    right:'10%',
                    bottom:'20%',
                    height:'190px',
                    avoidLabelOverlap: false,
                    center: ['50%', '35%'],
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 0
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: '14',
                            fontWeight: 'bold',
                            color:'#9DB9EB',
                            formatter: function(params) {
                                return params.percent + '%' + '\n' + params.name
                            },
                        }
                    },
                    labelLine: {
                        show: true
                    },
                    data: [
                        { value: data.dangyuan_meeting_total, name: '党员大会' },
                        { value: data.zhiwei_meeting_total, name: '党小组会' },
                        { value: data.dangxiaozu_meeting_total, name: '支委会' },
                        { value: data.dangke_meeting_total, name: '党课' },
                        { value: data.other_meeting_total, name: '其他会议' }
                    ]
                }
            ]
        };
        chart.setOption(option);
        /*const option = {
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                right: '0%',
                center: 'center',
                icon: 'circle',
                height: chartDOM.height,
                // itemGap:9,
                itemWidth:6,
                itemHeight:6,
                selectedMode:"true",
                textStyle: {
                    color: '#9DB9EB',
                    fontSize: oneLegendHeight,
                },
                data: ['双随机一公开', 'APCD检查', '许可证检查', '消费者投诉', '举报线索查办']
            },
            color: ['#972CF6', '#6D62F3', '#EC8644', '#419DEF','#22C6ED', '#749f83', '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
            series: [
                {
                    name: '占比统计',
                    type: 'pie',
                    radius: ['60%', '85%'],
                    center: ['40%', '50%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            formatter: '{d}% \n {b}',
                            fontSize: oneLegendHeight,
                            fontWeight: 'normal',
                            color: "#9DB9EB",
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {value: 335, name: '双随机一公开'},
                        {value: 310, name: 'APCD检查'},
                        {value: 234, name: '许可证检查'},
                        {value: 135, name: '消费者投诉'},
                        {value: 1548, name: '举报线索查办'}
                    ],
                    hoverOffset: 5
                }
            ]
        }*/

        // const {apcd_num, cigar_num, complaint_num, e_cigarette_num, enforce_activity_num,
        // field_audit_num, monitor_num, open_num, seized_case_num} = data;
        //
        // const seriesData = [
        //     apcd_num, cigar_num, complaint_num, e_cigarette_num, enforce_activity_num,
        //     field_audit_num, monitor_num, open_num, seized_case_num
        // ];
        //
        // const yaxisData = ['APCD检查', '雪茄烟', '消费者投诉', '发现售卖电子烟次数', '联合执法活动',
        //                     '新办许可证实地核查', '许可证后续监管', '双随机一公开', '举报线索查办'];

        // const option = {
        //     title: {
        //         text: '',
        //         // subtext: '数据来自网络'
        //     },
        //     color: [
        //         '#EC8644', '#22C6ED', '#419DEF',
        //         '#6D62F3', '#972CF6', '#213FF7',
        //     ],
        //     tooltip: {
        //         trigger: 'axis',
        //         axisPointer: {
        //             type: 'shadow'
        //         }
        //     },
        //     legend: {
        //         show: false,
        //         data: [],
        //         right: 'right',
        //         top: 'center',
        //         orient:"vertical",
        //         icon: 'circle',
        //     },
        //     grid: {
        //         top: '0',
        //         left: '0',
        //         right: '5%',
        //         bottom: '3%',
        //         containLabel: true
        //     },
        //     xAxis: {
        //         type: 'value',
        //         axisLine: {
        //             lineStyle: {
        //                 color: "#293c69",
        //             }
        //         },
        //         splitLine: {
        //             show: true,
        //             lineStyle: {
        //                 color: "#293c69"
        //             }
        //         },
        //         axisLabel: {
        //             color: "#8799C0",
        //         }
        //     },
        //     yAxis: {
        //         type: 'category',
        //         interval: 0,
        //         axisLine: {
        //             lineStyle: {
        //                 color: "#293c69",
        //             }
        //         },
        //         axisLabel: {
        //             fontSize: '10',
        //             color: "#8799C0",
        //         },
        //         data: yaxisData
        //     },
        //     series: [
        //         {
        //             name: '',
        //             type: 'bar',
        //             itemStyle: {
        //                 normal: {
        //                     color: function(params) {
        //                         const colorList = [
        //                             '#EC8644', '#22C6ED', '#419DEF', '#fb7293',
        //                             '#6D62F3', '#972CF6', '#213FF7', '#ff6c5f',
        //                         ];
        //                         return colorList[params.dataIndex]
        //                     },
        //                 },
        //             },
        //             data: seriesData
        //         }
        //     ]
        // };

        // chart.setOption(option);
    }

    static initColumnarChart(id_name='', dataList=[]) {
        let chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        let chart = echarts.init(chartDOM);

        if (dataList.length === 0) return;

        const xAxisList = [];
        const firstSeries = [];
        const secondSeries = [];
        dataList.forEach(item => {
            xAxisList.push(item.name);
            firstSeries.push(item.check_num);
            secondSeries.push(item.shop_num);
        })


        let option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                right: 0,
                orient: 'horizontal',
                textStyle: {
                    fontSize: 14,
                    color: "#8799C0"
                },
                // icon: 'roundRect',
                data: ['计划数量', '完成数量']
            },
            color: ['#22C6ED', '#d3783d', '#EC8644', '#419DEF','#22C6ED', '#749f83', '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],

            xAxis: [
                {
                    type: "category",
                    right: 0,
                    data: xAxisList,
                    axisPointer: {
                        show: true,
                        type: 'shadow',
                    },
                    axisLabel: {
                        rotate: 18,
                        color: "#8799C0",
                        textStyle: {
                            color: '#9DB9EB',
                            fontSize: '10'
                        },
                    }
                }
            ],
            grid:{
                left:'6%',
                right:0,
                top:'20%',
                bottom:'15%'
            },
            yAxis: [
                {
                    type: 'value',
                    name: '',
                    axisLabel: {
                        // formatter: '{value} ml',
                        textStyle: {
                            color: '#9DB9EB'
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69"
                        },
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: "#293c69"
                        }
                    },
                    axisPointer: {
                        show: false,
                    }

                },
                {
                    show:true,
                    type: 'value',
                    name: '',
                    axisLabel: {
                        // formatter: '{value} °C',
                        show:false,
                        textStyle: {
                            color: '#9DB9EB'
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "#293c69"
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#293c69"
                        }
                    },
                    axisPointer: {
                        show: false,
                    }
                }
            ],
            series: [
                {
                    name: '计划数量',
                    type: 'bar',
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            2, 0, 0, 1,
                            [
                                {offset: 1, color: '#213FF7'}
                            ]
                        )
                    },
                    data: firstSeries,
                    label: {
                        normal: {
                            show: true,//开启显示
                            position: 'top',//柱形上方
                            textStyle: { //数值样式
                                color: '#9DB9EB'
                            }
                        }
                    }
                },
                {
                    name: '完成数量',
                    type: 'bar',
                    yAxisIndex: 1,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            2, 0, 0, 1,
                            [
                                {offset: 0, color: '#22C6ED'},
                            ]
                        )
                    },
                    data: secondSeries,
                    // data: [1,5,7,6],
                    label: {
                        normal: {
                            show: true,//开启显示
                            position: 'top',//柱形上方
                            textStyle: { //数值样式
                                color: '#9DB9EB'
                            }
                        }
                    }
                },
            ]
        };

        chart.setOption(option);
    }

    static initPileChart(id_name='', type=0, dataList=[]) {
        const chartDOM = document.getElementById(id_name);

        if(chartDOM!=null){
                chartDOM.width = chartDOM.clientWidth;
                chartDOM.height = chartDOM.clientHeight;
                const chart = echarts.init(chartDOM);

            const xAxisList = [], yAxisList1 = [], yAxisList2 = [];
            function formatXAxis(str) {
                return type === 0 ? `${str}:00` : str
            }

            dataList.forEach(item => {
                xAxisList.push(formatXAxis(item.day_time));
                yAxisList1.push(item.plan_meeting_total);
                yAxisList2.push(item.open_meeting_total);
            })
            const option = {
                title: {
                    text: '',
                    padding: [0, 0, 0, 18],
                    textStyle: {
                        fontSize: 14,
                        color: "#8799C0"
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                        backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    show: true,
                    data: ['计划开展', '实际开展'],
                    top: '0%',
                    center: 'right',
                    textStyle: {
                        fontSize: 14,
                        color: "#8799C0"
                    },
                },
                color: ['#F4BB2D', '#448BE9',],
                grid: {
                    top: "18%",
                    left: '3%',
                    right: '4%',
                    bottom: '0',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisPointer: {
                            show: true,
                            type: 'shadow'
                        },
                        axisLabel: {
                            color: "#8799C0",
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#293c69",
                            }
                        },
                        data: xAxisList,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisPointer: {
                            show: false,
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#293c69",
                            }
                        },
                        axisLabel: {
                            color: "#8799C0",
                        },

                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: "#293c69"
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: '计划中会议',
                        type: 'line',
                        smooth: true,
                        data: yAxisList1,
                    },
                    {
                        name: '已开展会议',
                        type: 'line',
                        smooth: false,
                        data: yAxisList2,
                    }
                ]
            };
            chart.setOption(option);
        }
    }

    static initelectronicchart(id_name='',dataList=[]) {
        const chartDOM = document.getElementById(id_name);
        if(chartDOM!=null){
            chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);
        let nameList = []
        let check_numList = []
        let not_check_numList = []
        dataList.forEach((item,index)=>{
            nameList.push(item.name)
            check_numList.push(item.check_num)
            not_check_numList.push(item.not_check_num)
        })

        const option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
                right: 25,
                textStyle: {
                    fontSize: 14,
                    color: "#9DB9EB"
                },
            },
            color: ['#615ADF', '#35A7EA',],
            grid: {
              top:'9%',
              left: '0%',
              right: '8%',
              bottom: '8%',
              containLabel: true
            },
            xAxis: {
                type: 'value',
                axisLabel: {
                    color: "#8799C0",
                },
                axisLine: {
                    lineStyle: {
                        color: "#293c69",
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#293c69"
                    }
                },
                boundaryGap: [0, 0.01]
            },
            yAxis: {
              type: 'category',
              axisLine: {
                lineStyle: {
                    color: "#293c69",
                }
                },
                axisLabel: {
                    color: "#8799C0",
                },
                // splitLine: {
                //     show: true,
                //     lineStyle: {
                //         color: "#293c69"
                //     }
                // },
              data: nameList
            },
            series: [
              {
                name: '待核查',
                type: 'bar',
                data: not_check_numList
              },
              {
                name: '已核查',
                type: 'bar',
                data: check_numList
              }
            ]
          };

          chart.setOption(option);
        }
    }



    static initmanagementTypeChar(id_name='',dataList=[]){
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);
        let nameDate = [{ value: dataList.enterprise_num, name: '企业',ratio:dataList.enterprise_ratio},
                        { value: dataList.retail_num, name: '零售户',ratio:dataList.retail_ratio},]
        const option = {
            // title: {
            //     text: '主题类型对比',
            //     left: '30%',
            //     textStyle:{
            //         color: '#00AEFF',
            //         fontSize: 15
            //     }
            // },
            tooltip: {
              trigger: 'item',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              top: '65%',
              right:'8%',
              width:100,
              textStyle: {
                fontSize: 14,
                color: "#9DB9EB"
                },
            },
            color: ['#615ADF','#26A5E4'],
            graphic: [{
                type: 'text',
                left: '33%',
                top: '48%',
                z: 10,
                // style: {
                //     fill: '#FFFFFF',
                //     text: '电子烟',
                //     font: '16px Microsoft YaHei'
                // }
            }],
            series: [
              {
                type: 'pie',
                center:['38%','55%'],
                radius: ['35%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    formatter(params){
                        return params.data.ratio*100+"%"
                    },
                },
                data: nameDate
              }
            ]
          };
          chart.setOption(option);
    }


    static initmanagementCityChar(id_name='',dataList=[]){
        const chartDOM = document.getElementById(id_name);
        chartDOM.width = chartDOM.clientWidth;
        chartDOM.height = chartDOM.clientHeight;
        const chart = echarts.init(chartDOM);

        let nameList = []
        let shop_numList = []
        dataList.forEach((item,index)=>{
            nameList.push(item.name)
            shop_numList.push(item.shop_num)
        })

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
            },
            grid: {
              left: '0%',
              right: '5%',
              bottom: '5%',
              top:'8%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                axisPointer: {
                    show: true,
                },
                axisLine: {
                    lineStyle: {
                        color: "#293c69",

                    }
                },
                axisLabel: {
                    rotate: 30,
                    color: "#8799C0",
                    textStyle: {
                        fontSize: '12'
                    },
                },
                data: nameList,
                axisTick: {
                  alignWithLabel: true
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                axisPointer: {
                    show: false,
                },
                axisLine: {
                    lineStyle: {
                        color: "#293c69",
                    }
                },
                axisLabel: {
                    color: "#8799C0",
                },

                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#293c69"
                    }
                }
              }
            ],
            series: [
              {
                type: 'bar',
                barWidth: '60%',
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: '#22C6ED' },
                      { offset: 1, color: '#213FF7' }
                    ]),
                    barBorderRadius: [50, 50, 0 ,0]
                },
                data: shop_numList
              }
            ]
          };
          chart.setOption(option);
    }
}

<template>
<!--  <dv-full-screen-container class="home">-->
  <div class="home">
    <section class="home-container">
      <s-left-container />
      <s-center-container />
      <s-right-container />
    </section>
  </div>

<!--  </dv-full-screen-container>-->
</template>

<script>
import leftContainer from "@/components/left/left-container";
import centerContainer from "@/components/center/center-container";
import rightContainer from "@/components/right/right-container";
export default {
  name: "Home",
  components: {
    "s-left-container": leftContainer,
    "s-center-container": centerContainer,
    "s-right-container": rightContainer,
  },

  data() {
    return {
      timer: null,
      timer2: null,
    };
  },

  watch: {
    "$store.getters.org_area_id"() {
      this.initData();
    },
  },

  async mounted() {
    let active_name = this.$store.state.electronic.active_name;
    await this.$store.dispatch('getUserInfo')
    await this.$store.dispatch("getCheckAreaInsideData", {});
    await this.$store.dispatch('getCheckContentStatisticsData', {time_type:0});
    this.timer = setInterval(() => {
      this.$store.commit("GET_CURRENT_TIME");
    }, 1000);
    await this.$store.dispatch("getCheckSubordinateUnitData", {
      unit_id: 1,
    });
    await this.$store.dispatch("getCheckRealTimeData", {});
    await this.$store.dispatch("getCheckStatisticsData", {
      time_type: active_name,
    });
    await this.$store.dispatch("getNewExamineRecordData", {});
    let th = new Date();
    let year = th.getFullYear()
    let month = th.getMonth() + 1
    let monthsData = year+"-"+month
    await this.$store.dispatch('getExaminePersonRankingData',{date:monthsData})
    /**
     * 以上是中原大屏
     * */
  },

  methods: {
    initData() {},
  },

  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    this.timer = null;
    this.timer2 = null;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/mixin";

$bg-img1: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg2.png");
$bg-img2: url("http://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/data_bi/bg.png");

.home {
  width: 100%;
  //height: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  margin: 0 auto;
  background-image: $bg-img1, $bg-img2;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;

  .home-container {
    @include flex(space-between, normal);
    width: 100%;
    font-size: 80px;
    color: #fff;
    border: 1px solid #216d9e;
  }
}
</style>
